import Link from "next/link"
import classnames from "classnames"
import React from "react"
import { imageResizer } from "../../../services/utils/imageResizer"
import { Image } from "../Layout/Image"
import styles from "./CircleImages.module.css"

const CircleImages = ({ title, subtitle, image, href, to }) => {
  const initials = title
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
    .toUpperCase()
    .substring(0, 3)

  return (
    <Link
      href={href}
      as={to}
      className={classnames(styles["circle-image-container"], "margin m0a")}>
      {image ? (
        <Image
          src={imageResizer(image, 200, 200)}
          width="150px"
          height="150px"
          labelHeight="150px"
          labelWidth="150px"
          className={classnames(styles.circles, "mb-4", "mx-auto")}
          alt={title}
        />
      ) : (
        <div className={classnames(styles["gallery-logo"], "mb-4", "mx-auto")}>
          <p className={styles["gallery-inicials"]}>{initials}</p>
        </div>
      )}
      <h4 className="text-black text-center mb-2 font-secondary fw-700 fs-16px">
        {title}
      </h4>
      <p className="text-center color-gray-super-light font-primary fs-13px font-primary">
        {subtitle}
      </p>
    </Link>
  )
}

export default CircleImages
