import React from "react"
import classnames from "classnames"
import styles from "./Exhibitions.module.css"
import Link from "next/link"

const ExhibitionCard = (props) => {
  const { id, description, image, date, title, href, to, author } = props

  return (
    <Link href={href} as={to}>
      <div
        className={styles.exhibitions}
        style={{ backgroundImage: `url(${image})` }}>
        <div className={styles.exhibitionInfo}>
          <h4 className="color-white fs-16px">{title.toUpperCase()}</h4>
          <div className={styles.bottom}>
            <p className="color-white mb-0 font-primary fs-14px ">{author}</p>
            <span className="fs-13px">{date}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default ExhibitionCard
