import React from "react"
import { Col, Row } from "react-bootstrap"
import ExhibitionCard from "../../components/ExhibitionCard"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import { imageResizer } from "../../../services/utils/imageResizer"

export const FeaturedExhibitions = ({ featuredExhibitions }) => {
  const navigateTo = useNavigateTo()

  const onNavigate = async () => {
    await navigateTo("/exhibitions")
  }
  return (
    <div>
      <Row className="mb-2">
        <Col xs={12} sm={6} md={6} lg={6}>
          <h3 className="text-black mb-1 fs-24px">
            Featured Virtual Exhibitions
          </h3>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className="text-left text-sm-right">
          <span
            onClick={() => onNavigate()}
            className="font-primary td-u fs-14px cursor-pointer color-black">
            View all
          </span>
        </Col>
      </Row>
      <Row>
        {featuredExhibitions.map(({ exhibition }) => (
          <Col xs={12} md={3} lg={3} key={exhibition.id}>
            <ExhibitionCard
              id={exhibition.id}
              image={imageResizer(exhibition.image, 600, `AUTO`)}
              title={exhibition.title}
              date={exhibition.date}
              author={exhibition.author}
              to={`/exhibitions/${exhibition.id}`}
              href={"/exhibitions/[id]"}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}
