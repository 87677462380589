import classnames from "classnames"
import React from "react"
import styles from "./ButtonCta.module.scss"

export const ButtonCta = ({ className, onClick, title, children }) => (
  <button
    onClick={onClick}
    type="button"
    className={classnames(
      className,
      "btn btn-outline-secondary fs-12px tt-u",
      styles.button,
    )}>
    {title || children}
  </button>
)
