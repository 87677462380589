import React from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import { Masonry } from "../../components/Masonry"

export const FeaturedRooms = ({ featuredSpaces }) => {
  const navigateTo = useNavigateTo()

  const onNavigate = async () => {
    await navigateTo("/spaces")
  }

  return (
    <>
      <Row className="mb-2">
        <Col xs={12} sm={6} md={6} lg={6}>
          <h3 className="text-black mb-1 fs-24px">Featured Spaces</h3>
          <p className="color-gray-super-light font-primary fs-13px">
            Create the perfect art wall. See how the art pieces you love come
            together in space.
          </p>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className="text-left text-sm-right">
          <span
            onClick={() => onNavigate()}
            className="font-primary td-u fs-14px cursor-pointer color-black">
            View all
          </span>
        </Col>
      </Row>
      <Masonry
        id="featured-rooms"
        items={featuredSpaces.map((featuredSpace) => ({
          title: featuredSpace.gallery.name,
          subtitle: featuredSpace.space.title,
          image: featuredSpace.space.image,
          to: `/spaces/${featuredSpace.galleryspaceid}`,
          href: "/spaces/[id]",
        }))}
      />
    </>
  )
}
