import { useEffect, useState } from "react"

export const useDeviceDetection = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    isMobile: false,
    isIOS: false,
    isAndroid: false,
  })

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      const ua = navigator.userAgent

      const isAndroid = /android/i.test(ua)
      const isIOS =
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      const isMobile = isAndroid || isIOS || /Mobi|Android/i.test(ua)

      setDeviceInfo({
        isMobile,
        isIOS,
        isAndroid,
      })
    }
  }, [])

  return deviceInfo
}
