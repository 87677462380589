import React from "react"
import HomePage from "../features/pages/HomePage"
import galleryApiService from "../services/galleryApiService"

const Home = ({ homeInfo }) => {
  return <HomePage homeInfo={homeInfo} />
}

export const getStaticProps = async () => {
  const response = await galleryApiService.home()

  return {
    props: {
      homeInfo: response.data,
    },
    revalidate: parseInt(process.env.REVALIDATE),
  }
}

export default Home
