import Link from "next/link"
import React from "react"
import { imageResizer } from "../../../services/utils/imageResizer"
import { Image } from "../Layout/Image"
import styles from "./Masonry.module.scss"

export const Masonry = ({ items }) => {
  const Item = ({
    title,
    subtitle,
    subSubTitle,
    sold,
    price,
    lastLine,
    image,
    to,
    href,
    textAlign = "center",
  }) => {
    const Card = () => (
      <div className={`mb-2 text-${textAlign} ${styles.card}`}>
        <Image
          src={imageResizer(image, 800, "AUTO")}
          alt={title}
          className="mb-2 m0a"
        />
        <h4 className="text-black mb-0 font-secondary fw-700 fs-16px">
          {title}
        </h4>
        <p className="mb-0 font-primary text-black fs-14px font-primary">
          {subtitle}
        </p>

        {subSubTitle && (
          <p className="mb-0 color-gray-super-light fs-13px font-primary fs-09em">
            {subSubTitle}
          </p>
        )}

        {sold && (
          <span className="mb-2 text-black fs-14px font-secondary fw-700">
            Sold
          </span>
        )}
        {!sold && price && (
          <span className="mb-2 text-black fs-14px font-secondary fw-700">
            {price}
          </span>
        )}

        {lastLine && (
          <p className="mb-2 color-gray-super-light fs-13px font-primary fs-09em">
            {lastLine}
          </p>
        )}
      </div>
    )

    if (to && href) {
      return (
        <Link href={href} as={to} className={styles.item}>
          <Card />
        </Link>
      )
    }

    return <Card />
  }

  return (
    <div className={styles.row}>
      {items.map((item) => (
        <Item key={item.to} {...item} />
      ))}
    </div>
  )
}
