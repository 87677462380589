import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { ButtonCta } from "../Layout/ButtonCta"
import styles from "./Banner.module.css"
import classnames from "classnames"

const Banner = ({
  children,
  image,
  height = 500,
  title,
  description,
  cta = "CTA",
  onClick,
  contact = false,
}) => {
  const defaultImage = "/images/banner-gallery.png"

  return (
    <div
      className={classnames(
        styles.banner,
        "d-flex flex-column justify-content-center",
        "mb-4",
      )}
      style={{
        backgroundImage: `url(${image || defaultImage})`,
        height: `${height}px`,
      }}>
      <div className="container position-relative z-1">
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <div
              className={classnames(
                "banner-text",
                contact ? styles.bannerTopPadding : "",
              )}>
              <h1
                className={classnames(
                  "fw-300 text-white fs-56px fs-mobile-40px lh-100",
                  contact ? "mb-0" : "mb-4",
                )}>
                {title} <br />
              </h1>
              <div className="text-white font-primary fs-18px mb-4">
                {description} <br />
              </div>
              <ButtonCta onClick={onClick} title={cta} />
            </div>
          </Col>
        </Row>
      </div>
      {children}
    </div>
  )
}

export default Banner
