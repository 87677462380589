import React from "react"
import { Col, Row } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import { linksBuilder } from "../../../services/utils/linksBuilder"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import CircleImages from "../../components/CirclesImages"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 6,
    slidesToSlide: 5,
  },
  desktopSmall: {
    breakpoint: { max: 1200, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

export const HomeCarousel = ({ featuredGalleries }) => {
  const navigateTo = useNavigateTo()

  const onNavigate = async () => {
    await navigateTo("/galleries")
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
              <h3 className="text-black mb-1 fs-24px">Featured Galleries</h3>
              <p className="color-gray-super-light font-primary fs-13px">
                A selection of our favorite galleries and artists.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className="text-left text-sm-right">
          <span
            onClick={() => onNavigate()}
            className="font-primary td-u fs-14px cursor-pointer color-black">
            View all
          </span>
        </Col>
      </Row>
      <Row className="position-relative">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className="w-100"
          containerClass="container-with-dots home-galleries-carousel"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}>
          {featuredGalleries.map((featuredGallery) => (
            <Col key={`featured-galleries-${featuredGallery.gallery.id}`}>
              <CircleImages
                title={featuredGallery.gallery.name}
                subtitle={featuredGallery.gallery.address}
                image={featuredGallery.gallery.thumbnail}
                href={`/galleries/[id]`}
                to={linksBuilder.galleries(featuredGallery.gallery.domain)}
              />
            </Col>
          ))}
        </Carousel>
      </Row>
    </>
  )
}
