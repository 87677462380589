export const images = {
  home: {
    header: "/images/Home-Banner.jpg",
    footer1: "/images/Home-Banner-Footer.jpg",
    footer2: "/images/Home-Banner-Footer-2.jpg",
  },
  contact: {
    header: "/images/ContactUs-Banner-New.jpg",
    side: "/images/Contact-Side-New.jpg",
  },
}
