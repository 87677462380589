import React from "react"
import { Col, Row } from "react-bootstrap"
import { linksBuilder } from "../../../services/utils/linksBuilder"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import LikeCards from "../../components/LikeCards"
import { getArtworkSize } from "../../../services/utils/getArtworkSize"

export const MonthlyPicks = ({ artworks }) => {
  const navigateTo = useNavigateTo()

  const onNavigate = async () => {
    await navigateTo("/artworks")
  }

  return (
    <>
      <Row className="mb-2">
        <Col xs={12} sm={6} md={6} lg={6}>
          <h3 className="text-black mb-1 fs-24px">Inspiring Monthly Picks</h3>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className="text-left text-sm-right">
          <span
            onClick={() => onNavigate()}
            className="font-primary td-u fs-14px cursor-pointer color-black">
            View all
          </span>
        </Col>
      </Row>
      <Row>
        {artworks.map((artwork) => (
          <Col md={4} key={artwork.id}>
            <LikeCards
              title={artwork.title}
              subtitle={artwork.artistname}
              subSubTitle={getArtworkSize(artwork.width, artwork.height)}
              to={linksBuilder.artworks(artwork.id, artwork.title)}
              href="/artworks/[id]"
              image={artwork.image}
              type="big"
            />
          </Col>
        ))}
      </Row>
    </>
  )
}
