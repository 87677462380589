import React from "react"
import { Row, Col } from "react-bootstrap"
import { linksBuilder } from "../../../services/utils/linksBuilder"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import LikeCards from "../../components/LikeCards"
import { Masonry } from "../../components/Masonry"
import { getArtworkSize } from "../../../services/utils/getArtworkSize"

export const FeaturedArtworks = ({ featuredArtworks }) => {
  const navigateTo = useNavigateTo()

  const onNavigate = async () => {
    await navigateTo("/artworks")
  }

  return (
    <div>
      <Row className="mb-2">
        <Col xs={12} sm={6} md={6} lg={6}>
          <h3 className="text-black mb-1 fs-24px">Featured Artworks</h3>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className="text-left text-sm-right">
          <span
            onClick={() => onNavigate()}
            className="font-primary td-u fs-14px cursor-pointer color-black">
            View all
          </span>
        </Col>
      </Row>
      <Row>
        {featuredArtworks.map((featuredArtwork) => (
          <Col md={3} key={featuredArtwork.artwork.id}>
            <LikeCards
              title={featuredArtwork.artwork.title}
              subtitle={featuredArtwork.artwork.artistname}
              subSubTitle={getArtworkSize(
                featuredArtwork.artwork.width,
                featuredArtwork.artwork.height,
              )}
              to={linksBuilder.artworks(
                featuredArtwork.artwork.id,
                featuredArtwork.artwork.title,
              )}
              href="/artworks/[id]"
              image={featuredArtwork.artwork.image}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}
