import { useState } from "react"

export const useGetStartedAction = () => {
  const [email, setEmail] = useState("")

  const handleClick = () => {
    window.open(`https://www.artplacer.com/signup?email=${email}`)
  }

  return {
    email,
    setEmail,
    handleClick,
  }
}
