import React from "react"
import classnames from "classnames"
import styles from "./LoadingLabel.module.scss"

export const LoadingLabel = ({ height, width, maxWidth, className }) => (
  <span
    className={classnames(styles["loading-label"], className)}
    style={{ height, width, maxWidth }}
  />
)

LoadingLabel.defaultProps = {
  height: 16,
  width: "100%",
  className: "",
}
