import React from "react"
import classnames from "classnames"
import styles from "./Button.module.css"

const Button = ({
  name,
  children,
  isAppleButton,
  isAndroidButton,
  type = "button",
  onClick = () => {},
  size = "lg",
  width,
  disabled = false,
  variant = "dark",
}) => {
  return (
    <div
      className={classnames(
        styles["button-container"],
        "cursor-pointer",
        isAppleButton ? styles.apple : isAndroidButton ? styles.android : "",
      )}
      style={{ width: width ? width : "auto" }}
      onClick={() => onClick()}>
      <button
        disabled={disabled}
        type={type}
        className={classnames(
          `btn-${size} btn-sm btn-block btn-${variant} fw-700 fs-12px ${styles.button}`,
        )}>
        {name || children}
      </button>
    </div>
  )
}

export default Button
