import React, { useState, useRef, useEffect } from "react"
import { LoadingLabel } from "../LoadingLabel"

export const Image = ({
  src,
  labelHeight = "250px",
  labelWidth = "100%",
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false)
  const img = useRef()

  useEffect(() => {
    if (img.current.complete) {
      setLoaded(true)
    }
  })

  return (
    <>
      {!loaded && (
        <LoadingLabel
          className={rest.className}
          height={labelHeight}
          width={labelWidth}
        />
      )}
      <img
        src={src}
        ref={img}
        alt={rest.alt || ""}
        style={{ display: loaded ? "block" : "none" }}
        {...rest}
        onLoad={() => setLoaded(true)}
      />
    </>
  )
}
766
